.signup {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-image: url('https://media.istockphoto.com/videos/business-people-working-in-a-busy-open-plan-office-video-id1201224615?s=640x640');
    background-image: url('https://img.freepik.com/foto-gratis/ejecutivos-negocios-discutir-sus-colegas-whiteboa_1170-1837.jpg?t=st=1655158072~exp=1655158672~hmac=c3bbd2979400ddaa2347deb72f915403a6b56261cff0041416902fad24914207&w=1380');

    padding: 2rem;

    background:linear-gradient(0deg, var(--color-surface-elevation-5), var(--color-surface-elevation-5)), url('https://firebasestorage.googleapis.com/v0/b/enotario-91456.appspot.com/o/assets%2Fpexels-alexander-suhorucov-6457478%20(2).jpg?alt=media&token=64cfcb87-093e-407d-8737-afba6de62638');
    background-size: cover;
    background-position: center;
}

.signup-card {
    background: var(--color-surface);
    /* background: var(--color-surface-elevation-5); */
    border-radius: var(--shape-corner-large);
    width: 100%;
}

.signup-card-tint {
    display: flex;
    flex-direction: column;
    padding: 1.5rem;
    background: var(--color-surface-elevation-1);
    border-radius: var(--shape-corner-large);
    width: 100%;
}

/* .signup-card-tint svg {
    align-self: center;
} */



.signup-card h2 {
    text-align: center;
    margin: 1.5rem;
}

.signup-card form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 1rem;
}

.signup-card a {
    color: var(--color-primary);
}

.signup-logo {
    width: 128px;
}


@media only screen and (min-width: 600px) {

    .signup-card {
        width: 480px;
    }

    .signup-card-tint {
        padding: 2rem;
    }

}