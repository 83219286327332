.home-section-1{
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    gap: 1rem;
    margin: 2rem 0;
}


.elevated-card{
    background: var(--color-surface);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: var(--shape-corner-large);
}

.elevated-card-tint{
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 160px auto;
    height: 100%;
    width: inherit;
    background: var(--color-surface-elevation-1);
    border-radius: inherit;
}

.elevated-card-img{
    border-radius: var(--shape-corner-large);
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.elevated-card-content{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1.5rem;
    padding: 1.5rem;
}


.outlined-card{
    background: var(--color-surface);
    border: 1px solid var(--color-outline);
    border-radius: var(--shape-corner-large);
}

.outlined-card-content{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1.5rem;
    padding: 1.5rem;
}


@media only screen and (min-width: 600px) {
    .home-section-1{
        display: grid;
        grid-template-columns: 2fr 1fr;
        grid-template-rows: auto;
    }
    
    
    .elevated-card-tint{
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto;
    }
}

@media only screen and (min-width: 1920px) {

    .elevated-card-content{
        padding: 2rem;
    }

    .outlined-card-content{
        padding: 2rem;
    }
  
}