.top-app-bar{
    background: var(--color-surface);
    height: 64px;
    width: 100%;
    position: sticky;
    top: 0;
    left: 0;
    overflow: hidden;
    z-index: 100;
}

.top-app-bar-surface-tint{
    display: flex;
    align-items: center;
    height: inherit;
    width: inherit;
    position: relative;
    padding: 0 .5rem;
    transition: background-color 0.5s ease;
    background: var(--color-surface-elevation-0);
    /* backdrop-filter: blur(20px) saturate(180%); */
}

.top-app-bar-surface-tint-scroll{
    background: var(--color-surface-elevation-2);
}

.top-app-bar-title{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.top-app-bar-logo{
    height: 40px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@media only screen and (min-width: 600px) {
    .top-app-bar-surface-tint{
        padding: 0 1rem;
    }
}