.register {
    padding-bottom: 6rem;
}

.register h2 {
    margin-bottom: 0.75rem;
}

.register p {
    max-width: 60ch;
}

.bottom-bar {
    height: 80px;
    width: 100%;
    background: var(--color-surface);
    /* backdrop-filter: blur(20px) saturate(180%); */
    position: fixed;
    bottom: 80px;
    right: 0;
    z-index: 1;
    border-radius: var(--shape-corner-large-top);
    animation-name: intro;
    animation-duration: .3s;
}

.bottom-bar-tint {
    height: inherit;
    width: 100%;
    background: var(--color-surface-elevation-1);
    border-radius: inherit;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem;
    position: relative;
}

.bottom-progress-bar-step-counter {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@keyframes intro {
    from {
        bottom: 0px;
    }

    to {
        bottom: 80px;
    }
}

@media only screen and (min-width: 600px) {

    .bottom-bar {
        width: calc(100% - 80px);
        bottom: 0px;
    }

    .bottom-bar-tint {
        background: var(--color-surface-elevation-2);
    }

    @keyframes intro {
        from {
            bottom: -80px;
        }

        to {
            bottom: 0px;
        }
    }

}

@media only screen and (min-width: 1240px) {

    .bottom-bar {
        width: calc(100% - 320px);
    }

}


.step {
    display: none;
    animation-name: tabin;
    animation-duration: .3s;
}

@keyframes tabin {
    from {
        opacity: 0;
        /* transform: translateX(24px); */
    }

    to {
        opacity: 1;
        /* transform: translateX(0); */
    }
}

.step-header {
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
}

.step-header-check {
    margin-left: 1rem;
    color: var(--color-primary);
}

.step-header button {
    margin-left: auto;
}


/* STEP 1 CATEGORIES */

.categories-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: .5rem;
    margin: 2rem 0;
}

.category {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.25rem;
    height: calc((100vw - 48px) / 3);
    border-radius: 8px;
    background: var(--color-surface-elevation-1);
    position: relative;
    cursor: pointer;
    text-align: center;
}


@media only screen and (min-width: 600px) {

    .categories-container {
        display: flex;
        flex-wrap: wrap;
    }


    .category {
        height: 128px;
        width: 128px;
    }


}

.category:hover {
    background: var(--color-surface-elevation-2);
}


.category-active {
    border: 1px solid var(--color-primary);
}

.category-radio-button {
    position: absolute;
    top: .75rem;
    right: .75rem;
}

.bottom-progress-bar-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}

.medio {
    height: 16px;
    border-radius: 16px 16px 0 0;
    overflow: auto;
}

.bottom-progress-bar {
    height: 4px;
    width: 20%;
    border-radius: px;
    background: var(--color-primary);
    transition: width .3s ease-out;

}


/* @media only screen and (min-width: 600px) {

    .categories-container>div {
        height: 128px;
        width: 128px;
    }

} */

/* STEP 2 TITLE */

.register-title-input-container {
    position: relative;
}

.register-title-input {
    border: none;
    width: 100%;
    height: 80px;
    border-radius: var(--shape-corner-large-top);
    border-bottom: 1px solid var(--color-outline);
    background: var(--color-surface-elevation-1);
    padding: 0 2rem;
    font-family: var(--typescale-body-large-font);
    font-weight: var(--typescale-body-large-weight);
    font-size: var(--typescale-body-large-size);
    line-height: var(--typescale-body-large-line-height);
    letter-spacing: var(--typescale-body-large-tracking);
    color: var(--color-on-surface);
}

.register-title-input:focus {
    border-bottom: 2px solid var(--color-on-primary-container);
}

.register-title-input:hover:not(:focus) {
    border-color: var(--color-surface-variant);
}

.register-title-input-label {
    position: absolute;
    left: 2rem;
    top: 1.75rem;
    font-size: 1rem;
    transition: top 0.2s, font-size 0.2s;
    background-color: inherit;
    /* background-color: var(--color-surface); */
    border-color: var(--color-on-surface);
    padding: 0 0.25rem;
}

.register-title-input:focus+.register-title-input-label {
    top: 0.5rem;
    font-size: 0.875rem;
    color: var(--color-primary);
}

.register-title-input:not([value=""]):not(:focus)+.register-title-input-label {
    top: 0.5rem;
    font-size: 0.875rem;
    color: #5f6368;
}

/* STEP 3 FILE */

.register-file-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    height: 200px;
    background: var(--color-surface-elevation-1);
    border-radius: var(--shape-corner-large);
    border: 1px dashed var(--color-outline);
    margin: 2rem 0;
    transition: background-color 0.3s, box-shadow 0.3s;
}

.register-file-hash-container {
    padding: 0.5rem;
    background: var(--color-neutral);
    border-radius: var(--shape-corner-small);
    border: 1px solid var(--color-outline);
    color: var(--color-on-surface-variant);
    word-wrap: break-word;
    margin: 1rem 0;
    display: flex;
    align-items: center;
    gap: 0.5rem;
}


/* STEP 4 ROLES */

.register-roles-component {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    gap: 1rem;
    margin: 2rem 0;
}

.register-roles-list {
    display: flex;
    flex-direction: column;
    gap: .5rem;
}

.register-roles-list-item {
    padding: 1rem;
    border-radius: var(--shape-corner-small);
    background: var(--color-surface-elevation-1);
    position: relative;
}

.register-roles-iso2 {
    position: absolute;
    right: 1rem;
    bottom: 1rem;
}

.register-roles-list-item-delete {
    position: absolute;
    right: 0.25rem;
    top: 0.25rem;
    color: var(--color-error);
}

@media only screen and (min-width: 600px) {

    .register-roles-component {
        display: grid;
        grid-template-columns: 480px 1fr;
        grid-template-rows: 1fr;
        gap: 1rem;
    }

}


/* SNACKBAR */

main {
    /* position: relative; */
    /* background: cyan; */
}

.snackbar {
    display: none;
    align-items: center;
    height: 48px;
    min-width: 344px;
    background: var(--color-inverse-surface);
    color: var(--color-inverse-on-surface);
    border-radius: var(--shape-corner-extra-small);
    position: fixed;
    bottom: 180px;
    left: 50%;
    padding: 0 1rem;
    transform: translateX(-50%);
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.4);
    animation-name: snackbarin;
    animation-duration: 5s;
}

@keyframes snackbarin {
    0% {
        opacity: 0;
    }

    10% {
        opacity: 1;
    }

    75% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        display: none;
    }
}



.snackbar button {
    color: var(--color-inverse-primary);
    margin-left: auto;
}

@media only screen and (min-width: 600px) {

    .snackbar {
        bottom: 100px;
        left: calc(50vw + 40px);
    }


}


@media only screen and (min-width: 1240px) {

    .snackbar {
        left: calc(50vw + 180px);
    }


}