.certificate{
    /* text-align: justify; */
    text-justify: inter-word;
}

.certificate-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2rem;
}


.certificate-logo{
    max-width: 256px;
    max-height: 112px;
    margin: 2rem 0;
}

.certificate-details-container{
    padding: 1rem;
    border: 1px solid var(--color-outline);
    border-radius: var(--shape-corner-small);
}

.certificate-details-container p{
    display: inline-block;
    word-break: break-all;
}

@media print {
    .certificate{--webkit-print-color-adjust: exact;}
    .top-app-bar{display: none;}
    .navigation-bar{display: none;}
    .navigation-drawer{display: none;}
    #cert-download-button{display: none;}
    @page :footer {
        display: none
    }
 
    @page :header {
        display: none
    }

    @page {
        margin-top: 0;
        margin-bottom: 0;
    }
    body {
        padding-top: 72px;
        padding-bottom: 72px ;
    }
}