.app{
  /* overflow-x: hidden; */
}

aside{ 
  display: none;
}

main{
  z-index: 10;
  padding: 1rem 1rem 7rem 1rem;

}

.page{
  margin-top: 0;
  animation-name: pagein;
  animation-duration: .3s;
}

@keyframes pagein {
  from {
    opacity: 0;
    margin-top: 24px;
  }
  to {
    opacity: 1;
    margin-top: 0;
  }
}

.app-fab{
  position: fixed;
  bottom: 96px;
  right: 16px;
  animation-name: fabin;
  animation-duration: .3s;
}

@keyframes fabin {
  from {
    opacity: 0;
    transform: scale(0.85);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@media only screen and (min-width: 600px) {

  aside{ 
    display: block;
    width: 80px;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 90;
    /* background: var(--color-surface); */
  }

  main{
    z-index: 10;
    padding: 2rem 2rem 2rem 2rem;
    margin-left: 80px;
  }

  .app-fab{ display: none; }

}

@media only screen and (min-width: 1240px) {

  aside{
    width: 360px;
  }

  main{
    z-index: 10;
    padding: 2rem 2rem 2rem 2rem;
    margin-left: 360px;
  }

  aside{
    width: 320px;
  }

  main{
    z-index: 10;
    padding: 2rem 2rem 2rem 2rem;
    margin-left: 320px;
  }

}

@media only screen and (min-width: 1664px) {

  .page{
    width: 1280px;
    margin: auto;
  }

}

@media only screen and (min-width: 1920px) {

  aside{
    width: 360px;
  }

}


/* BANNER */

.banner{
  display: none;
  width: 100%;
  padding-left: 0rem;
  position: sticky;
  top: 4rem;
  right: 0;
  overflow: hidden;
  z-index: 80;
  animation-name: bannerin;
  animation-duration: .3s;
}

@keyframes bannerin {
  from {
    transform: translateY(-64px);
  }
  to {
    transform: translateY(0px);
  }
}

.banner-content{
  height: 100%;
  width: 100%;
  background: var(--color-surface);
  /* background: var(--color-surface-elevation-0);
  backdrop-filter: blur(20px) saturate(180%); */
  border-bottom: 1px solid var(--color-outline);
  padding: 1rem 1rem 0.5rem 1rem;
}


.banner-buttons-container{
  display: flex;
  gap: 0.5rem;
  margin-left: auto;
}

@media only screen and (min-width: 600px) {
  .banner{
    padding-left: 96px;
  }
}

@media only screen and (min-width: 1240px) {
  .banner{
    padding-left: 376px;
  }
}


/* DIALOG */


.dialog-scrim{
  display: none;
  align-items: center;
  justify-content: center;
  height: var(--app-height);
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 500;
  padding: 1rem;
  animation-name: dialogScrimIn;
  animation-duration: .3s;
  transition: opacity .3s;
}

@keyframes dialogScrimIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.dialog{
  max-width: 480px;
  border-radius: var(--shape-corner-large);
  background: var(--color-surface);
  animation-name: dialogin;
  animation-duration: .3s;
}

@keyframes dialogin {
  from {
    opacity: 0;
    transform: translateY(32px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

.dialog-tint{
  height: inherit;
  width: inherit;
  border-radius: inherit;
  background: var(--color-surface-elevation-3);
  padding: 1.5rem;
}

.dialog-header{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1rem;
  gap: 1rem;
}

.dialog-buttons-container{
  display: flex;
  gap: 0.5rem;
  margin-top: 1.5rem;
}