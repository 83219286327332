.cls-1 {
    fill: #001b66;
}


.cls-2 {
    fill: url(#linear-gradient);
}

.cls-3 {
    fill: url(#linear-gradient-2);
}

.cls-4 {
    stroke: #fff;
    stroke-miterlimit: 10;
    stroke-width: 7px;
    fill: url(#linear-gradient-3);
}

@media (prefers-color-scheme: dark) {
    .cls-1 {
        fill: var(--color-on-background);
    }

    .cls-2 {
        fill: var(--color-primary);
    }
}

.cls-5 {
    fill: #fff;
}

.cls-6 {
    stroke: none;
}

.cls-7 {
    fill: none;
}


.loading-logo{
    width: 180px;
}