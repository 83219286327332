.records-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}


.records-search-container{
    position: relative;
}

.records-search-container input{
    border-radius: 24px;
    height: 48px;
    background: var(--color-surface-elevation-1);
    border: none;
    padding: 0 1rem;
}

.records-search-container svg{
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
}

.records-dialog-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin: 4rem 0;
    gap: 1rem;
    padding: 0 2rem;
}

.records-dialog-buttons-container {
    display: flex;
    gap: 1rem;
    margin-top: 0.5rem;
}